import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';

@Injectable({ providedIn: 'root' })
export class TranslocoLoaderService implements TranslocoLoader {
    constructor() {}

    getTranslation(lang: string): Promise<Translation> {
        return import(`../../../assets/i18n/${lang}.json`);
    }
}
